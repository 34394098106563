import {createStyles, makeStyles} from "@material-ui/styles";
import * as React from "react";
import {connect} from "react-redux";
import {RouteComponentProps} from "react-router-dom";
import {RootState} from "../reducers";
import VdfAppBar from "../components/AppBar/VdfAppBar";
import {CardHeader, Divider, Theme, Typography} from "@material-ui/core";
import {bindActionCreators, Dispatch} from "redux";
import {Article, Comment, stringToCategory} from "../model/model";

import ErrorSnackbar from "../components/common/ErrorSnackbar";
import {goBack} from "connected-react-router";
import VdfAvatar from "../components/common/VdfAvatar/VdfAvatar";
import {Selectors} from "../selectors";
import {ActionCreators} from "../actions";
import LoadingSpinner from "../components/common/LoadingSpinner";
import CommentSection from "../components/ArticleComment/CommentSection";
import WriteCommentSection from "../components/ArticleComment/WriteCommentSection";


interface IProps {
    article?: Article,
    isLoading: boolean,
    errorMessage?: string,
    isLoadingComments: boolean,
    commentsErrorMessage?: string,
    comments?: Comment[],
    isSavingComment: boolean,
    commentInputText: string,
    commentSavingError?: string
}

interface IPropsFromDispatch {
    goBack: typeof goBack,
    loadArticle: typeof ActionCreators.loadArticle,
    saveComment: typeof ActionCreators.saveComment,
    setCommentText: typeof ActionCreators.setCommentText
}

type IAllProps = IProps & IPropsFromDispatch & RouteComponentProps<{ articleId: string }>;


const ArticleDetailPage = (props: IAllProps) => {
    const classes = useStyles();


    React.useEffect(() => {
        props.loadArticle(props.match.params.articleId);
    }, []);

    const onSaveComment = () => {
        if (props.article) {
            props.saveComment(props.article.id, props.commentInputText);
        }
    };

    const onUpdateComment = (text: string) => {
        if (props.article) {
            props.setCommentText(props.article.id, text);
        }
    };

    return (
        <div className={classes.root}>
            <VdfAppBar
                onBackClicked={props.goBack}
                title={"Blogbeitrag"}
                displayLogo={true}
            />
            <div>

                {props.isLoading && <LoadingSpinner/>}

                {props.article && (

                    <React.Fragment>
                        <CardHeader
                            avatar={
                                <VdfAvatar category={stringToCategory(props.article.catid)}/>
                            }
                            title={props.article.category_title}
                            subheader={props.article.author}
                        />
                        <Divider/>
                        <article className={classes.article}>
                            <Typography variant="caption" align="right" color="textSecondary" component="time"
                                        className={classes.time}>
                                {new Date(props.article.published_date).toLocaleDateString()}
                            </Typography>
                            <Typography variant="h2" color="primary" className={classes.title}>
                                {props.article.title}
                            </Typography>
                            {
                                props.article.images.image_intro &&
                                <img src={props.article.images.image_intro}
                                     alt={props.article.images.image_fulltext_alt}
                                     className={classes.image}
                                />
                            }
                            <div dangerouslySetInnerHTML={{__html: props.article.content}}/>
                        </article>
                        <Divider/>

                        <div className={classes.comment}>

                            <CommentSection
                                isLoadingComments={props.isLoadingComments}
                                comments={props.comments}
                                errorMessage={props.commentsErrorMessage}
                            />
                            <WriteCommentSection
                                text={props.commentInputText}
                                setText={onUpdateComment}
                                isLoading={props.isSavingComment}
                                onButtonPress={onSaveComment}
                            />
                        </div>


                    </React.Fragment>
                )}


                <ErrorSnackbar errorMessage={props.errorMessage || props.commentSavingError}/>
            </div>


        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        height: "100%",
        flexGrow: 1,
        backgroundColor: 'white',
    },
    title: {
        fontSize: '1.1rem',
        fontWeight: 'bold',
        lineHeight: '1.375rem'
    },
    time: {
        display: 'block',
        marginBottom: theme.spacing(1),

    },
    article: {
        padding: theme.spacing(3, 2),
        backgroundColor: '#fff',
    },

    image: {
        width: '80%',
        display: 'block',
        margin: '8px auto',
        height: 'auto'
    },
    comment: {
        background: theme.palette.background.default
    }


}));

const mapStateToProps = (state: RootState): IProps => ({
    article: Selectors.selectActiveArticle(state),
    isLoading: Selectors.isArticleDetailLoading(state),
    errorMessage: Selectors.articleDetailError(state),
    isLoadingComments: Selectors.isCommentsLoading(state),
    commentsErrorMessage: Selectors.commentsError(state),
    comments: Selectors.selectActiveComments(state),
    isSavingComment: Selectors.isCommentSaving(state),
    commentInputText: Selectors.commentText(state),
    commentSavingError: Selectors.commentSavingError(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
    goBack: goBack,
    loadArticle: ActionCreators.loadArticle,
    saveComment: ActionCreators.saveComment,
    setCommentText: ActionCreators.setCommentText
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(ArticleDetailPage);
