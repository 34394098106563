import createReducer from "./createReducer";
import {Action, ActionType, PayloadLoginSuccess} from "../model/model";

export interface LoginState {
    isLoading: boolean,
    errorMessage?: string
}

export const loginReducer = createReducer<LoginState>({
    isLoading: false
}, {
    [ActionType.LOGIN_START](state: LoginState, action: Action<void>) {
        return {
            ...state,
            isLoading: true,
            errorMessage: undefined
        };
    },
    [ActionType.LOGIN_ERROR](state: LoginState, action: Action<string>) {
        return {
            ...state,
            isLoading: false,
            errorMessage: action.payload
        };
    },
    [ActionType.LOGIN_SUCCESS](state: LoginState, action: Action<PayloadLoginSuccess>) {
        return {
            isLoading: false
        };
    }
});

