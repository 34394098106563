import {RootState} from "../reducers";
import {Article, Comment} from "../model/model";
import {SingleArticleState} from "../reducers/articleDetail";


export function activeArticleId(state: RootState): string | undefined {
    return state.articleDetailState.activeArticleId;
}

function singleArticleState(state: RootState): SingleArticleState | undefined {
    const id = activeArticleId(state);
    return id ? state.articleDetailState.articles[id] : undefined;
}

export function isArticleDetailLoading(state: RootState): boolean {
    const singleState = singleArticleState(state);
    return singleState ? singleState.isLoading : false;
}

export function articleDetailError(state: RootState): string | undefined {
    const singleState = singleArticleState(state);
    return singleState ? singleState.errorMessage : undefined;
}

export function selectActiveArticle(state: RootState): Article | undefined {
    const singleState = singleArticleState(state);
    return singleState ? singleState.article : undefined;
}

export function isCommentsLoading(state: RootState): boolean {
    const singleState = singleArticleState(state);
    return singleState ? singleState.isLoadingComment : false;
}

export function commentsError(state: RootState): string | undefined {
    const singleState = singleArticleState(state);
    return singleState ? singleState.errorMessageComment : undefined;
}

export function selectActiveComments(state: RootState): Comment[] | undefined {
    const activeArticle = selectActiveArticle(state);
    return activeArticle ? activeArticle.comments as Comment[] : undefined;
}

export function isCommentSaving(state: RootState): boolean {
    const singleState = singleArticleState(state);
    return singleState ? singleState.isSavingComment : false;
}

export function commentSavingError(state: RootState): string | undefined {
    const singleState = singleArticleState(state);
    return singleState ? singleState.errorMessageSaveComment : undefined;
}

export function commentText(state: RootState): string {
    const singleState = singleArticleState(state);
    return singleState ? singleState.comment : "";
}


