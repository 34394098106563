import createReducer from "./createReducer";
import {
    Action,
    ActionType,
    Article,
    ArticleLoadingError,
    CommentError,
    CommentLoadingSuccess,
    CommentSavingSuccess,
    SetCommentText
} from "../model/model";
import produce from "immer";


export interface SingleArticleState {
    isLoading: boolean,
    errorMessage?: string,
    article?: Article,
    isLoadingComment: boolean,
    errorMessageComment?: string,
    isSavingComment: boolean,
    errorMessageSaveComment?: string,
    comment: string
}

export interface ArticleDetailState {
    activeArticleId?: string,
    articles: {
        [id: string]: SingleArticleState
    },
}

export const articleDetailReducer = createReducer<ArticleDetailState>({
    articles: {},
}, {
    [ActionType.ARTICLE_DETAIL_START_LOADING](state: ArticleDetailState, action: Action<string>) {
        return produce(state, draftState => {
            if (action.payload in state.articles) {
                let singleState = draftState.articles[action.payload];
                singleState.isLoading = true;
                singleState.errorMessage = undefined;
                singleState.isLoadingComment = false;
                singleState.errorMessageComment = undefined;
                singleState.comment = ""
            } else {
                draftState.articles[action.payload] = {
                    isLoading: true,
                    isLoadingComment: false,
                    isSavingComment: false,
                    comment: ""
                };
            }
        });
    },
    [ActionType.ARTICLE_DETAIL_SUCCESS_LOADING](state: ArticleDetailState, action: Action<Article>) {

        return produce(state, draftState => {
            if (action.payload.id in state.articles) {
                let singleState = draftState.articles[action.payload.id];
                singleState.isLoading = false;
                singleState.article = action.payload;
            }
        });

    },
    [ActionType.ARTICLE_DETAIL_ERROR_LOADING](state: ArticleDetailState, action: Action<ArticleLoadingError>) {
        return produce(state, draftState => {
            if (action.payload.articleId in state.articles) {
                let singleState = draftState.articles[action.payload.articleId];
                singleState.isLoading = false;
                singleState.errorMessage = action.payload.errorMessage;
            }
        });
    },
    [ActionType.ARTICLE_DETAIL_SET_ARTICLE_ID](state: ArticleDetailState, action: Action<string>) {
        return produce(state, draftState => {
            draftState.activeArticleId = action.payload;
        });
    },
    [ActionType.COMMENTS_START_LOADING](state: ArticleDetailState, action: Action<string>) {
        return produce(state, draftState => {
            if (action.payload in state.articles) {
                let singleState = draftState.articles[action.payload];
                singleState.isLoadingComment = true;
                singleState.errorMessageComment = undefined;
            }
        });
    },
    [ActionType.COMMENTS_ERROR_LOADING](state: ArticleDetailState, action: Action<CommentError>) {
        return produce(state, draftState => {
            if (action.payload.articleId in state.articles) {
                let singleState = draftState.articles[action.payload.articleId];
                singleState.isLoadingComment = false;
                singleState.errorMessageComment = action.payload.errorMessage;
            }
        });
    },
    [ActionType.COMMENTS_SUCCESS_LOADING](state: ArticleDetailState, action: Action<CommentLoadingSuccess>) {
        return produce(state, draftState => {
            if (action.payload.articleId in state.articles) {
                let singleState = draftState.articles[action.payload.articleId];
                singleState.isLoadingComment = false;
                if (singleState.article) {
                    singleState.article.comments = action.payload.comments;
                }
            }
        });
    },
    [ActionType.SAVE_COMMENTS_START_LOADING](state: ArticleDetailState, action: Action<string>) {
        return produce(state, draftState => {
            if (action.payload in state.articles) {
                let singleState = draftState.articles[action.payload];
                singleState.isSavingComment = true;
                singleState.errorMessageSaveComment = undefined;
            }
        });
    },
    [ActionType.SAVE_COMMENTS_ERROR_LOADING](state: ArticleDetailState, action: Action<CommentError>) {
        return produce(state, draftState => {
            if (action.payload.articleId in state.articles) {
                let singleState = draftState.articles[action.payload.articleId];
                singleState.isSavingComment = false;
                singleState.errorMessageSaveComment = action.payload.errorMessage;
            }
        });
    },
    [ActionType.SAVE_COMMENTS_SUCCESS_LOADING](state: ArticleDetailState, action: Action<CommentSavingSuccess>) {
        return produce(state, draftState => {
            if (action.payload.articleId in state.articles) {
                let singleState = draftState.articles[action.payload.articleId];
                singleState.isSavingComment = false;
                singleState.comment = "";
                if (singleState.article && Array.isArray(singleState.article.comments)) {
                    singleState.article.comments.unshift(action.payload.comment);
                }
            }
        });
    },
    [ActionType.COMMENT_SET_TEXT](state: ArticleDetailState, action: Action<SetCommentText>) {
        return produce(state, draftState => {
            if (action.payload.articleId in state.articles) {
                let singleState = draftState.articles[action.payload.articleId];
                singleState.comment = action.payload.text;
            }
        });
    },

});



