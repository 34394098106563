import {ActionType} from "../model/model";
import {replace} from "connected-react-router";
import {RootState} from "../reducers";
import {api} from "../ReduxRoot";

export function logout() {
    return async (dispatch: Function, getState: () => RootState) => {

        dispatch({type: ActionType.LOGOUT_START});
        try {
            await api.logout();
            dispatch(redirectAfterLogout());
        } catch (e) {
            console.log(e);
            let errorMessage = "Fehler beim Logout.";
            dispatch({type: ActionType.LOGOUT_ERROR, payload: errorMessage});
        }

    };
}

export function redirectAfterLogout() {
    return async (dispatch: Function, getState: () => RootState) => {
        dispatch({type: ActionType.LOGOUT_SUCCESS});
        dispatch(replace("/login"));
    };
}

