import {RootState} from "../reducers";


export function username(state: RootState): string {
    return state.userState.username || "";
}

export function password(state: RootState): string {
    return state.userState.password || "";
}

export function isLoggedIn(state: RootState): boolean {
    return state.userState.sessionId !== undefined
}

export function sessionId(state: RootState): string | undefined {
    return state.userState.sessionId;
}
