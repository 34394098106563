import createReducer from "./createReducer";
import {Action, ActionType, Articles, Category, Comment, PayloadArticleList} from "../model/model";
import produce from "immer";

export interface ArticleListState {
    pages: Map<Category, Articles>,
    isLoading: boolean,
    activeFilter: Category,
    errorMessage?: string,
    comments?: Comment[]
}

export const articleListReducer = createReducer<ArticleListState>({
    pages: new Map<Category, Articles>(),
    isLoading: false,
    activeFilter: Category.NONE
}, {
    [ActionType.ARTICLE_LIST_START_LOADING](state: ArticleListState, action: Action<void>) {
        return {
            ...state,
            isLoading: true,
            errorMessage: undefined
        };
    },
    [ActionType.ARTICLE_LIST_COMMENTS](state: ArticleListState, action: Action<Comment[]>) {
        return {
            ...state,
            comments: action.payload
        };
    },
    [ActionType.ARTICLE_LIST_SUCCESS_LOADING](state: ArticleListState, action: Action<PayloadArticleList>) {

        return produce(state, draftState => {
            draftState.isLoading = false;
            if (action.payload.currentPage === 1 || state.pages.get(action.payload.category) === undefined) {
                draftState.pages.set(action.payload.category, {
                    pages: new Map([[action.payload.currentPage, action.payload.articles]]),
                    totalPages: action.payload.totalPages
                });
            } else {
                const pages = draftState.pages.get(action.payload.category)!;
                pages.totalPages = action.payload.totalPages;
                pages.pages.set(action.payload.currentPage, action.payload.articles);
            }
        });

    },
    [ActionType.ARTICLE_LIST_ERROR_LOADING](state: ArticleListState, action: Action<string>) {
        return {
            ...state,
            isLoading: false,
            errorMessage: action.payload,
        };
    },
    [ActionType.ARTICLE_LIST_FILTER](state: ArticleListState, action: Action<Category>) {
        return {
            ...state,
            activeFilter: action.payload,
        };
    },

});

