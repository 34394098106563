import {History} from "history";
import {combineReducers} from "redux";
import {connectRouter} from 'connected-react-router';
import {userReducer, UserState} from "./user";
import {loginReducer, LoginState} from "./login";
import {logoutReducer, LogoutState} from "./logout";
import {articleListReducer, ArticleListState} from "./articleList";
import {articleDetailReducer, ArticleDetailState} from "./articleDetail";
import {createArticleReducer, CreateArticleState} from "./createArticle";

export interface RootState {
    loginState: LoginState,
    userState: UserState,
    logoutState: LogoutState,
    articleListState: ArticleListState,
    articleDetailState: ArticleDetailState,
    createArticleState: CreateArticleState

}

export default (history: History) =>
    combineReducers({
        router: connectRouter(history),
        userState: userReducer,
        loginState: loginReducer,
        logoutState: logoutReducer,
        articleListState: articleListReducer,
        articleDetailState: articleDetailReducer,
        createArticleState: createArticleReducer
    });
