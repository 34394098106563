import axios, {AxiosInstance} from "axios";
import {ApiError, NetworkError} from "./apiErrors";
import {
    IArticleListResponse,
    IArticleResponse,
    ICommentsResponse,
    ICreateResourceResponse,
    ILoginResponse,
    IStatusResponse
} from "./apiResponses";
import Config from "./config";
import qs from "qs";
import {ActionType, Article, Category} from "../model/model";
import {RootState} from "../reducers";
import {Selectors} from "../selectors";
import {ActionCreators} from "../actions";
import {absolutify} from "./absolutify";

class Api {
    private axios: AxiosInstance;

    private readonly dispatch: Function;
    private readonly getState: () => RootState;

    constructor(dispatch: Function, getState: () => RootState) {
        this.axios = axios.create({
            auth: Config.basicAuth,
            timeout: 15000,
            withCredentials: false,
            params: {
                "api_key": Config.apiKey
            },

        });
        this.dispatch = dispatch;
        this.getState = getState;
        this.setupResponseInterceptor();
    }

    public async login(username: string, password: string): Promise<ILoginResponse> {
        const result = await this.axios.request({
            url: `${Config.apiEndpoint}/post/user/login`,
            method: 'POST',
            headers: {'content-type': 'application/x-www-form-urlencoded'},
            data: qs.stringify({
                username: username,
                password: password
            }),
        });
        return result.data;
    }

    public async logout(): Promise<IStatusResponse> {
        const sessionId = Selectors.sessionId(this.getState());
        const result = await this.axios.request({
            url: `${Config.apiEndpoint}/get/user/logout`,
            params: {session_id: sessionId},
            method: 'GET',
        });
        return result.data;

    }

    public async fetchArticleList(limit: number, offset: number, category: Category, recursionCount = 1): Promise<IArticleListResponse> {

        const sessionId = Selectors.sessionId(this.getState());

        const result = await this.axios.request({
            url: `${Config.apiEndpoint}/get/content/articles`,
            params: {
                session_id: sessionId,
                limit: limit,
                offset: offset,
                orderby: 'created',
                orderdir: 'desc',
                catid: category === Category.NONE ? undefined : category,
            },
            method: 'GET',
        });

        const categories = Object.keys(Category).map(k => Category[k as any]);
        result.data.articles = result.data.articles.filter((article: Article) => categories.includes(article.catid));

        if (result.data.articles.length < 3 && result.data.pages_current < result.data.pages_total && recursionCount < 5) {
            const secondRequestResult = await this.fetchArticleList(limit, offset + limit, category, recursionCount + 1);
            return {
                ...secondRequestResult,
                articles: [...result.data.articles, ...secondRequestResult.articles]
            }
        }

        return result.data;
    }


    public async fetchArticle(articleId: string): Promise<IArticleResponse> {

        const sessionId = Selectors.sessionId(this.getState());

        const result = await this.axios.request({
            url: `${Config.apiEndpoint}/get/content/articles`,
            params: {
                session_id: sessionId,
                id: articleId,
            },
            method: 'GET',
            transformResponse: (data, headers) => JSON.parse(data, contentParser)
        });

        return result.data;
    }

    public async fetchComments(articleId?: string): Promise<ICommentsResponse> {

        const result = await this.axios.request({
            url: `${Config.apiEndpoint}/get/komento/comments`,
            params: {
                cid: articleId
            },
            method: 'GET',
        });

        return result.data;
    }

    public async saveComment(articleId: string, comment: string): Promise<ICreateResourceResponse> {


        const result = await this.axios.request({
            url: `${Config.apiEndpoint}/post/komento/create`,
            params: {
                component: "com_content",
                cid: articleId,
                comment
            },
            method: 'POST',
        });

        return result.data;
    }

    public async saveArticle(title: string, content: string, category: Category): Promise<ICreateResourceResponse> {

        const result = await this.axios.request({
            url: `${Config.apiEndpoint}/post/content/createarticle`,
            params: {
                title,
                content,
                catid: category,
                language: "de-DE"
            },
            method: 'POST',
        });


        return result.data;


    }


    private setupResponseInterceptor() {

        const id = this.axios.interceptors.response.use((response) => {
            const data = response.data;
            if (isApiError(data)) {
                const apiError = new ApiError(data.error_code, data.error_description);

                //could be unauthorized request
                if (!["CNT_ANA", "CNT_AGE"].includes(apiError.code)) {
                    return Promise.reject(apiError);
                }

                console.log("Session expired, reauthenticating...");
                //prevent loops
                this.axios.interceptors.response.eject(id);

                const refreshCall = this.refreshSessionId();

                // Create interceptor that will bind all the others requests
                // until refreshTokenCall is resolved
                const requestQueueInterceptorId = this.axios.interceptors
                    .request
                    .use(request => refreshCall.then(() => request));

                return refreshCall.then(() => {
                    console.log("reauthentication successful!");
                    this.axios.interceptors.request.eject(requestQueueInterceptorId);
                    response.config.params.session_id = Selectors.sessionId(this.getState());
                    return this.axios(response.config);

                }).catch(error => {
                    console.log("reauthentication failed!");
                    this.axios.interceptors.request.eject(requestQueueInterceptorId);
                    return Promise.reject(error)
                }).finally(() => this.setupResponseInterceptor());
            }
            return response;
        }, (error) => {
            console.log(error);
            throw new NetworkError();
        });
    }

    private async refreshSessionId() {

        const state = this.getState();
        const username = Selectors.username(state);
        const password = Selectors.password(state);

        try {
            const loginResult = await this.login(username, password);
            this.dispatch({
                type: ActionType.LOGIN_SUCCESS,
                payload: {
                    username: username,
                    password: password,
                    sessionId: loginResult.session_id
                }
            });
            return Promise.resolve();
        } catch (e) {
            this.dispatch(ActionCreators.redirectAfterLogout());
            return Promise.reject(e);
        }
    }


}

function contentParser(key, value) {

    if (key === 'content') {
        return absolutify(value, "https://www.vdfnet.de");
    }
    return value;
}


function isApiError(data: any): boolean {
    return data.status === 'ko';
}

export default Api;
