import {createStyles, makeStyles} from "@material-ui/styles";
import * as React from "react";
import {Divider, ListItem, ListItemText, Theme, Typography} from "@material-ui/core";
import {formatRelativeDate} from "../../util/util";
import clsx from 'clsx';


interface Props {
    author: string,
    comment: string,
    publishedAt: string,
    responseTo?: string,
}


const ArticleComment = (props: Props) => {

    const classes = useStyles();
    const relativeDate = formatRelativeDate(props.publishedAt);

    return (
        <React.Fragment>
            <ListItem className={clsx(classes.listItem, props.responseTo ? classes.answer : classes.comment)}
                      alignItems="flex-start">
                <ListItemText
                    primary={
                        <div className={classes.primaryRow}>
                            <div className={classes.author}>
                                <Typography className={classes.bold} variant="body1">{props.author}</Typography>
                                {props.responseTo && <Typography variant="caption">{props.responseTo}</Typography>}
                            </div>
                            <Typography className={classes.date} variant="caption">{relativeDate}</Typography>
                        </div>
                    }
                    secondary={props.comment}
                    secondaryTypographyProps={{color: "textPrimary"}}
                />
            </ListItem>
            <Divider component="li"/>
        </React.Fragment>
    );
};

const useStyles = makeStyles((theme: Theme) => createStyles({
    listItem: {},
    comment: {
        backgroundColor: theme.palette.grey[100],
    },
    answer: {
        backgroundColor: theme.palette.grey[300],
    },
    primaryRow: {
        display: 'flex',
    },
    author: {
        flexGrow: 1,
    },
    bold: {
        fontWeight: 'bold'
    },
    date: {}
}));

export default ArticleComment;


