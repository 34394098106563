import {createStyles, makeStyles} from "@material-ui/styles";
import * as React from "react";
import {TextField, Theme} from "@material-ui/core";
import LoadingButton from "../common/LoadingButton";


interface Props {
    onButtonPress: () => void,
    isLoading: boolean,
    text: string,
    setText: (text: string) => void
}

const WriteCommentSection = (props: Props) => {

    const classes = useStyles();
    const isBtnDisabled = props.text.length < 1;

    return (
        <section className={classes.comments}>
            <TextField
                label="Ihr Kommentar "
                multiline
                rows="3"
                value={props.text}
                onChange={(e) => props.setText(e.target.value)}
                className={classes.textField}
                variant="outlined"
                InputProps={{
                    classes: {
                        input: classes.input,
                    },
                }}
            />
            <LoadingButton
                title="Absenden"
                color={"primary"}
                disabled={isBtnDisabled}
                isLoading={props.isLoading}
                variant={"outlined"}
                className={classes.button}
                onClick={props.onButtonPress}
            />

        </section>
    );
};

const useStyles = makeStyles((theme: Theme) => createStyles({
    comments: {
        padding: theme.spacing(0, 2, 2, 2)
    },
    button: {
        marginTop: theme.spacing(1),
        marginLeft: 0,
    },
    textField: {
        width: '100%',
    },
    input: {
        fontSize: theme.typography.body2.fontSize
    }

}));

export default WriteCommentSection;


