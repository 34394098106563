import createReducer from "./createReducer";
import {Action, ActionType} from "../model/model";

export interface LogoutState {
    isLoading: boolean,
    errorMessage?: string
}

export const logoutReducer = createReducer<LogoutState>({
    isLoading: false
}, {
    [ActionType.LOGOUT_START](state: LogoutState, action: Action<void>) {
        return {
            ...state,
            isLoading: true,
            errorMessage: undefined
        };
    },
    [ActionType.LOGOUT_ERROR](state: LogoutState, action: Action<string>) {
        return {
            ...state,
            isLoading: false,
            errorMessage: action.payload
        };
    },
    [ActionType.LOGOUT_SUCCESS](state: LogoutState, action: Action<void>) {
        return {
            isLoading: false
        };
    }
});

