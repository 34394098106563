import {ActionType} from "../model/model";
import {ApiError, NetworkError} from "../util/apiErrors";
import {push} from "connected-react-router";
import {api} from "../ReduxRoot";

export function login(username: string, password: string, redirectTo: string) {
    return async (dispatch: Function, getState: Function) => {

        dispatch({type: ActionType.LOGIN_START});
        try {
            const loginResult = await api.login(username, password);
            dispatch({
                type: ActionType.LOGIN_SUCCESS,
                payload: {
                    username: username,
                    password: password,
                    sessionId: loginResult.session_id
                }
            });
            dispatch(push(redirectTo));
        } catch (e) {
            console.log(e);
            let errorMessage = "Fehler beim Einloggen.";
            if (e instanceof NetworkError) {
                errorMessage = e.message;
            } else if (e instanceof ApiError) {
                switch (e.code) {
                    case "USR_LIF":
                        errorMessage = "Benutzername oder Passwort falsch.";
                        break;
                    case "USR_UNE":
                        errorMessage = "Der Benutzer ist deaktiviert.";
                        break;
                    case "USR_ALI":
                        errorMessage = "Bitte loggen Sie sich aus und versuchen es erneut.";
                        break;
                }
            }
            dispatch({type: ActionType.LOGIN_ERROR, payload: errorMessage});
        }

    };
}


