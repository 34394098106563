import {Action, ActionType, Category,} from "../model/model";
import {api} from "../ReduxRoot";
import {RootState} from "../reducers";


export function saveArticle(title: string, content: string, category: Category) {
    return async (dispatch: Function, getState: () => RootState) => {

        dispatch({type: ActionType.CREATE_ARTICLE_START_LOADING});
        try {
            await api.saveArticle(title, content, category);
            dispatch({type: ActionType.CREATE_ARTICLE_SUCCESS_LOADING});
        } catch (e) {
            dispatch({
                type: ActionType.CREATE_ARTICLE_ERROR_LOADING,
                payload: "Ihr Artikel konnte leider nicht erstellt werden."
            });
        }
    };
}

export function resetCreateArticleState(): Action<void> {
    return {
        type: ActionType.CREATE_ARTICLE_RESET,
        payload: undefined
    };
}
