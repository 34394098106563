import {Button, CircularProgress, createStyles, makeStyles, Theme} from "@material-ui/core";
import * as React from "react";
import {ButtonProps} from "@material-ui/core/Button";


interface IProps {
    title: string,
    isLoading: boolean
    buttonClass?: string
}

type IAllProps = IProps & ButtonProps;

const LoadingButton = (props: IAllProps) => {

    const classes = useStyles();

    const {title, isLoading, buttonClass, className, ...other} = props;

    return <div className={`${classes.wrapper} ${className}`}>
        <Button {...other} disabled={props.disabled || isLoading} className={buttonClass}>
            {title}
        </Button>
        {isLoading &&
        <CircularProgress color="secondary" size={24} className={classes.buttonProgress}/>}
    </div>;
};

const useStyles = makeStyles((theme: Theme) => createStyles({
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
        display: 'inline-block',
        marginTop: theme.spacing(2)
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export default LoadingButton;