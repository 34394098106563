import {createStyles, makeStyles} from "@material-ui/styles";
import * as React from "react";
import {Theme} from "@material-ui/core";
import {Article, stringToCategory} from "../../model/model";
import ArticleCard from "../ArticleCard/ArticleCard";
import {ActionCreators} from "../../actions";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingSpinner from "../common/LoadingSpinner";

interface Props {
    loadFunc: typeof ActionCreators.loadArticles,
    hasMore: boolean,
    articles: Article[],
    scrollableTarget?: React.ReactNode | string
}

const ArticleList = (props: Props) => {

    const classes = useStyles();

    return (
        <InfiniteScroll
            dataLength={props.articles.length}
            next={props.loadFunc}
            hasMore={props.hasMore}
            scrollableTarget={props.scrollableTarget}
            loader={
                <LoadingSpinner/>
            }
        >
            {props.articles && props.articles.map(article => {
                return (
                    <ArticleCard
                        className={classes.card}
                        key={article.id}
                        id={article.id}
                        category={stringToCategory(article.catid)}
                        categoryTitle={article.category_title}
                        author={article.author}
                        date={new Date(article.published_date)}
                        title={article.title}
                        commentCount={article.comments as (number | undefined)}
                    />);
            })}
        </InfiniteScroll>
    );
};

const useStyles = makeStyles((theme: Theme) => createStyles({
    card: {
        marginBottom: theme.spacing(3),
    },
}));

export default ArticleList;


