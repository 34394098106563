export enum ActionType {
    LOGIN_START,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGOUT_START,
    LOGOUT_SUCCESS,
    LOGOUT_ERROR,
    ARTICLE_LIST_START_LOADING,
    ARTICLE_LIST_SUCCESS_LOADING,
    ARTICLE_LIST_ERROR_LOADING,
    ARTICLE_LIST_FILTER,
    ARTICLE_LIST_COMMENTS,
    ARTICLE_DETAIL_SET_ARTICLE_ID,
    ARTICLE_DETAIL_START_LOADING,
    ARTICLE_DETAIL_SUCCESS_LOADING,
    ARTICLE_DETAIL_ERROR_LOADING,
    COMMENTS_START_LOADING,
    COMMENTS_SUCCESS_LOADING,
    COMMENTS_ERROR_LOADING,
    SAVE_COMMENTS_START_LOADING,
    SAVE_COMMENTS_SUCCESS_LOADING,
    SAVE_COMMENTS_ERROR_LOADING,
    CREATE_ARTICLE_RESET,
    CREATE_ARTICLE_START_LOADING,
    CREATE_ARTICLE_SUCCESS_LOADING,
    CREATE_ARTICLE_ERROR_LOADING,
    COMMENT_SET_TEXT,
}

export interface Action<T> {
    type: ActionType;
    payload: T;
}

export interface PayloadLoginSuccess {
    username: string,
    password: string,
    sessionId: string
}

export interface PayloadArticleList {
    category: Category,
    articles: Article[],
    totalPages: number,
    currentPage: number
}

export interface CommentLoadingSuccess {
    articleId: string,
    comments: Comment[]
}

export interface CommentSavingSuccess {
    articleId: string,
    comment: Comment
}

export interface SetCommentText {
    articleId: string,
    text: string
}

export interface CommentError {
    articleId: string,
    errorMessage: string
}

export interface ArticleLoadingError {
    articleId: string,
    errorMessage: string
}


export interface Articles {
    pages: Map<number, Article[]>,
    totalPages: number
}

export interface ArticleImages {
    "image_intro": string,
    "float_intro": string,
    "image_intro_alt": string,
    "image_intro_caption": string,
    "image_fulltext": string,
    "float_fulltext": string,
    "image_fulltext_alt": string,
    "image_fulltext_caption": string,
}

export enum Category {
    NONE = "-1",
    VON_MITGLIEDERN = "47",
    AUS_ARBEITSGRUPPEN = "26",
    VOM_VORSTAND = "24",
}

export function stringToCategory(category: string): Category {
    switch (category) {
        case Category.VON_MITGLIEDERN:
            return Category.VON_MITGLIEDERN;
        case Category.AUS_ARBEITSGRUPPEN:
            return Category.AUS_ARBEITSGRUPPEN;
        case Category.VOM_VORSTAND:
            return Category.VOM_VORSTAND;
        default:
            return Category.NONE;
    }
}

export interface Article {
    id: string,
    title: string,
    alias: string,
    content: string,
    catid: string,
    images: ArticleImages,
    category_title: string,
    author: string,
    published_date: string,
    comments?: Comment[] | number
}

export interface Comment {
    id: string,
    cid: string,
    name: string,
    comment: string,
    publish_up: string,
    parent_id: string,
    answers?: string
}


