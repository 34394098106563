import {createStyles, makeStyles} from "@material-ui/styles";
import * as React from "react";
import {Badge, Button, Card, CardActions, CardContent, CardHeader, Theme, Typography} from "@material-ui/core";
import {Category} from "../../model/model";
import VdfAvatar from "../common/VdfAvatar/VdfAvatar";
import {Link} from "react-router-dom";
import {Comment} from "@material-ui/icons";

interface Props {
    id: string,
    category: Category,
    categoryTitle: string,
    author: string,
    date: Date,
    title: string,
    image?: string,
    imageAlt?: string,
    className?: string,
    commentCount?: number

}

const ArticleCard = (props: Props) => {

    const classes = useStyles();

    return (
        <Card className={props.className}>
            <CardHeader
                avatar={
                    <VdfAvatar category={props.category}/>
                }
                title={props.categoryTitle}
                subheader={props.author}
            />
            <CardContent className={classes.cardContent}>
                <Typography variant="caption" align="right" component="time" className={classes.time}>
                    {props.date.toLocaleDateString()}
                </Typography>
                <Typography variant="h2" className={classes.title}>
                    {props.title}
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
                <Button color="primary" component={Link} {...{to: `/artikel/${props.id}`}}>Weiter lesen</Button>
                {props.commentCount !== 0 && (
                    <Badge color="primary" badgeContent={props.commentCount} className={classes.commentCount}>
                        <Comment className={classes.commentIcon}/>
                    </Badge>
                )}
            </CardActions>
        </Card>
    );
};

const useStyles = makeStyles((theme: Theme) => createStyles({
    media: {
        height: 0,
        backgroundSize: 'contain',
        paddingTop: '33.33%',
        marginBottom: theme.spacing(1)
    },
    cardContent: {
        padding: theme.spacing(3, 2, 6, 2),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    imageWrapper: {
        backgroundColor: 'white',
        textAlign: 'center',
        padding: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    image: {
        maxHeight: 100
    },
    title: {
        fontSize: '1.375rem',
    },
    time: {
        display: 'block',
        marginBottom: theme.spacing(1),
    },
    commentCount: {
        marginLeft: 'auto',
        marginRight: theme.spacing(2)
    },
    commentIcon: {
        color: theme.palette.grey[600]
    }

}));

export default ArticleCard;


