import * as UserSelectors from "./user";
import * as LoginSelectors from "./login";
import * as LogoutSelectors from "./logout";
import * as ArticleListSelectors from "./articleList";
import * as ArticleDetailSelectors from "./articleDetail";
import * as CreateArticleSelectors from "./createArticle";

export const Selectors = Object.assign(
    {},
    UserSelectors,
    LoginSelectors,
    LogoutSelectors,
    ArticleListSelectors,
    ArticleDetailSelectors,
    CreateArticleSelectors
);