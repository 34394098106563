import {createStyles, makeStyles} from "@material-ui/styles";
import * as React from "react";
import {connect} from "react-redux";
import {RouteComponentProps} from "react-router-dom";
import {RootState} from "../reducers";
import VdfAppBar from "../components/AppBar/VdfAppBar";
import {TextField, Theme, Typography} from "@material-ui/core";
import LoadingButton from "../components/common/LoadingButton";
import {bindActionCreators, Dispatch} from "redux";
import {ActionCreators} from "../actions";
import {Selectors} from "../selectors";
import ErrorSnackbar from "../components/common/ErrorSnackbar";

interface IProps {
    username: string,
    errorMessage?: string,
    loading: boolean,

}

interface IPropsFromDispatch {
    login: typeof ActionCreators.login
}

type IAllProps = IProps & IPropsFromDispatch & RouteComponentProps<void>;



const LoginPage = (props: IAllProps) => {
    const classes = useStyles();

    const [username, setUsername] = React.useState(props.username);
    const [password, setPassword] = React.useState("");

    const login = () => {

        const referrer = props.location.state && props.location.state.from ? props.location.state.from : "/";
        props.login(username, password, referrer);
    };

    return (
        <div className={classes.root}>
            <VdfAppBar title={"Login"} displayLogo={true}/>
            <div className={classes.content}>
                <TextField
                    required
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className={classes.textField}
                    label="Benutzername"
                    margin="normal"
                />
                <TextField
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className={classes.textField}
                    type="password"
                    autoComplete="current-password"
                    label="Passwort"
                    margin="normal"
                />
                <LoadingButton title="Anmelden" variant="contained" color="primary" onClick={login}
                               disabled={props.loading} isLoading={props.loading}/>
                <a href="https://www.vdfnet.de/login?view=reset" rel="noopener noreferrer" target="_blank"
                   className={classes.forgotPw}>
                    Passwort vergessen?
                </a>
                <Typography variant="body2" className={classes.text}>
                    Für den Internen Bereich der Mitglieder ist die Mitgliedsnummer und das erhaltene bzw. selbst
                    definierte Passwort zu nutzen.
                    Die "Passwort vergessen"-Funktion ist nur für Mitglieder und Fördermitglieder für den internen
                    Bereich nutzbar.
                </Typography>

                <ErrorSnackbar errorMessage={props.errorMessage}/>

            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        height: "100%",
        flexGrow: 1,
    },
    content: {
        padding: '8px 24px',
    },
    textField: {
        width: '100%',
    },
    loginButton: {},
    forgotPw: {
        marginTop: '2rem',
        display: 'block',
        color: theme.palette.primary.main,
    },
    text: {
        marginTop: '1rem'
    },


}));

const mapStateToProps = (state: RootState): IProps => ({
    username: Selectors.username(state),
    loading: Selectors.loginIsLoading(state),
    errorMessage: Selectors.loginErrorMessage(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
    login: ActionCreators.login
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
