import {createStyles, makeStyles} from "@material-ui/styles";
import * as React from "react";
import {Button, Divider, SvgIcon, Theme, Typography} from "@material-ui/core";
import {useAddToHomescreenPrompt} from "../../util/useAddToHomescreenPrompt";


interface Props {
}

const AppInstallationBanner = (props: Props) => {

    const classes = useStyles();
    const [prompt, promptToInstall] = useAddToHomescreenPrompt();
    const [isPromptVisible, showPrompt] = React.useState(false);
    const [shouldDisplayOnIos, setShouldDisplayOnIos] = React.useState(false);

    function checkShouldDisplayOnIos() {

        // never show it if the app was launched standalone
        if (window.matchMedia('(display-mode: standalone)').matches) {
            return false;
        }

        // get the current date and the date the user last visited
        const now = Date.now();
        const then = document.cookie.match(/pwa_install_prompt_last_visit=([0-9]+)/);

        // update the pwa_install_prompt_last_visit cookie with Date.now()
        document.cookie = "pwa_install_prompt_last_visit=" + now + "; max-age=" + (60 * 60 * 24 * 180);

        // get the number of days since the user last visited and check if they're using iOS
        const DAYS = (then != null && then.length >= 2) ? Math.ceil((parseInt(then[1]) - now) / (1000 * 60 * 60 * 24)) : NaN;
        const IOS = ["iPad", "iPhone", "iPod"].indexOf(navigator.platform) > -1;

        // show if the cookie isn't set, or last visit is > show_after, and it's iOS
        return (isNaN(DAYS) || DAYS > 7) && IOS;
    }


    React.useEffect(() => {
        if (prompt) showPrompt(true);
    }, [prompt]);

    React.useEffect(() => {
        setShouldDisplayOnIos(checkShouldDisplayOnIos())
    }, []);

    function acceptInstallationProposal() {
        promptToInstall();
        showPrompt(false);
    }

    if (isPromptVisible) {
        return (
            <React.Fragment>
                <div className={classes.banner}>
                    <Typography variant="body2" className={classes.text}>
                        Sie können diese App nun zum Homescreen hinzufügen, um schneller darauf zugreifen zu können.
                    </Typography>
                    <div className={classes.buttons}>
                        <Button color="primary" size="small" onClick={() => showPrompt(false)}>Verwerfen</Button>
                        <Button color="primary" size="small" onClick={acceptInstallationProposal}>Installieren</Button>
                    </div>
                </div>
                <Divider/>
            </React.Fragment>
        )
    } else if (shouldDisplayOnIos) {
        return (
            <React.Fragment>
                <div className={classes.banner}>
                    <Typography variant="body2" className={classes.text}>
                        Drücken Sie <ShareIcon className={classes.shareIcon}/>
                        am unteren Bildschirmrand und dann “Zum Home-Bildschirm”, um diese App zu installieren.
                    </Typography>
                    <div className={classes.buttons}>
                        <Button color="primary" size="small"
                                onClick={() => setShouldDisplayOnIos(false)}>Verwerfen</Button>
                    </div>
                </div>
                <Divider/>
            </React.Fragment>
        )
    }
    return null;
};

const useStyles = makeStyles((theme: Theme) => createStyles({
    banner: {
        padding: theme.spacing(2, 2, 1, 2)
    },
    text: {
        paddingTop: theme.spacing(1)
    },
    icon: {
        fontSize: '2rem',
        color: theme.palette.grey["600"],
        paddingRight: theme.spacing(1)
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    shareIcon: {
        marginRight: 3,
        width: '0.75em',
        height: '0.75em',
    }

}));

function ShareIcon(props: { className: string }) {
    return (
        <SvgIcon className={props.className} viewBox='0 0 128 128'>
            <path fill="#007AFF"
                  d="M48.883,22.992L61.146,10.677L61.146,78.282C61.146,80.005 62.285,81.149 64,81.149C65.715,81.149 66.854,80.005 66.854,78.282L66.854,10.677L79.117,22.992C79.693,23.57 80.256,23.853 81.114,23.853C81.971,23.853 82.534,23.57 83.11,22.992C84.25,21.848 84.25,20.125 83.11,18.981L65.997,1.794C65.715,1.511 65.421,1.215 65.139,1.215C64.563,0.932 63.718,0.932 62.861,1.215C62.579,1.498 62.285,1.498 62.003,1.794L44.89,18.981C43.75,20.125 43.75,21.848 44.89,22.992C46.029,24.149 47.744,24.149 48.883,22.992ZM103.936,35.32L81.114,35.32L81.114,41.053L103.936,41.053L103.936,121.27L24.064,121.27L24.064,41.053L46.886,41.053L46.886,35.32L24.064,35.32C20.928,35.32 18.355,37.904 18.355,41.053L18.355,121.27C18.355,124.419 20.928,127.003 24.064,127.003L103.936,127.003C107.072,127.003 109.645,124.419 109.645,121.27L109.645,41.053C109.645,37.891 107.072,35.32 103.936,35.32Z"/>
        </SvgIcon>
    );
}


export default AppInstallationBanner;


