import {RootState} from "../reducers";
import {Article, Category} from "../model/model";


export function selectActiveFilter(state: RootState): Category {
    return state.articleListState.activeFilter;
}

export function articleList(state: RootState): Article[] {

    const activeFilter = selectActiveFilter(state);
    const articlesForCategory = state.articleListState.pages.get(activeFilter);
    if (articlesForCategory === undefined) return [];

    const list = Array.from(articlesForCategory.pages.values()).reduce((prev, current) => prev.concat(current), []);
    return list.map(a => {
        a.comments = countComments(state, a.id);
        return a;
    });
}


export function currentPage(state: RootState): number {

    const activeFilter = selectActiveFilter(state);

    const articlesForCategory = state.articleListState.pages.get(activeFilter);
    if (articlesForCategory === undefined) return 0;


    const pages = Array.from(articlesForCategory.pages.keys());
    return Math.max(...pages);
}

export function canLoadMoreArticles(state: RootState): boolean {

    const activeFilter = selectActiveFilter(state);

    const articlesForCategory = state.articleListState.pages.get(activeFilter);
    if (articlesForCategory === undefined) return true;

    const totalPages = articlesForCategory.totalPages;
    return currentPage(state) < totalPages;
}

export function articleErrorMessage(state: RootState): string | undefined {
    return state.articleListState.errorMessage;
}

export function countComments(state: RootState, articleId: string): number | undefined {
    return state.articleListState.comments ? state.articleListState.comments.filter(c => c.cid === articleId).length : undefined;
}

