import {
    Action,
    ActionType,
    ArticleLoadingError,
    Comment,
    CommentError,
    CommentLoadingSuccess,
    CommentSavingSuccess,
    SetCommentText
} from "../model/model";
import {Selectors} from "../selectors";
import {api} from "../ReduxRoot";
import {RootState} from "../reducers";

export function loadArticle(id: string) {
    return async (dispatch: Function, getState: () => RootState) => {

        dispatch({type: ActionType.ARTICLE_DETAIL_SET_ARTICLE_ID, payload: id});
        if (Selectors.selectActiveArticle(getState()) === undefined) {
            dispatch({type: ActionType.ARTICLE_DETAIL_START_LOADING, payload: id});
            try {
                const {status, ...articleResponse} = await api.fetchArticle(id);
                dispatch({
                    type: ActionType.ARTICLE_DETAIL_SUCCESS_LOADING,
                    payload: articleResponse
                });
                dispatch(loadComments(articleResponse.id));
            } catch (e) {
                console.log(e);
                dispatch({
                    type: ActionType.ARTICLE_DETAIL_ERROR_LOADING,
                    payload: {
                        articleId: id,
                        errorMessage: "Artikel konnte nicht geladen werden.",
                    } as ArticleLoadingError
                });
            }
        } else {
            //refresh comments
            dispatch(loadComments(id));
        }
    };
}

export function saveComment(articleId: string, comment: string) {
    return async (dispatch: Function, getState: () => RootState) => {

        dispatch({type: ActionType.SAVE_COMMENTS_START_LOADING, payload: articleId});
        try {
            await api.saveComment(articleId, comment);
            dispatch({
                type: ActionType.SAVE_COMMENTS_SUCCESS_LOADING,
                payload: {
                    articleId: articleId,
                    comment: {
                        id: new Date().getTime().toString(),
                        cid: articleId,
                        name: "Sie",
                        comment: comment,
                        publish_up: new Date().toISOString(),
                        parent_id: ""
                    }
                } as CommentSavingSuccess
            });
        } catch (e) {
            dispatch({
                type: ActionType.SAVE_COMMENTS_ERROR_LOADING,
                payload: {
                    articleId,
                    errorMessage: "Ihr Kommentar konnte nicht gespeichert werden."
                } as CommentError
            });

        }
    };
}

export function loadComments(articleId: string) {
    return async (dispatch: Function, getState: () => RootState) => {

        dispatch({type: ActionType.COMMENTS_START_LOADING, payload: articleId});
        try {
            const response = await api.fetchComments(articleId);
            dispatch({
                type: ActionType.COMMENTS_SUCCESS_LOADING,
                payload: {
                    articleId: articleId,
                    comments: sortComments(response.comments)
                } as CommentLoadingSuccess
            });
        } catch (e) {
            console.log(e);
            dispatch({
                type: ActionType.COMMENTS_ERROR_LOADING,
                payload: {
                    articleId: articleId,
                    errorMessage: "Fehler beim Laden der Kommentare."
                } as CommentError
            });
        }

    };
}

export function setCommentText(articleId: string, text: string): Action<SetCommentText> {
    return {
        type: ActionType.COMMENT_SET_TEXT,
        payload: {articleId, text}
    };
}

function sortComments(comments: Comment[]): Comment[] {
    let sorted = new Array<Comment>();
    comments.reverse().forEach(comment => {
        if (comment.parent_id === "0") {
            sorted.unshift(comment)
        } else {
            const insertionIndex = sorted.findIndex(c => c.id === comment.parent_id);
            if (insertionIndex >= 0) {
                const referringComment = sorted[insertionIndex];
                comment.answers = `↳ Antwort an ${referringComment.name}`;
                sorted.splice(insertionIndex + 1, 0, comment);
            }
        }
    });
    return sorted;
}


