import {createStyles, makeStyles} from "@material-ui/styles";
import * as React from "react";
import {AppBar, IconButton, Link, Menu, MenuItem, Theme, Toolbar, Typography} from "@material-ui/core";
import {ArrowBack, MoreVert} from "@material-ui/icons";
import logo from "./logo-vdf.png";
import {ActionCreators} from "../../actions";
import {RootState} from "../../reducers";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";
import {Selectors} from "../../selectors";

interface IProps {
    title: string
    displayLogo: boolean,
    onBackClicked?: () => void,
    actions?: React.ReactNode,
}

interface IPropsFromStore {
    isLogoutEnabled: boolean
}

interface IPropsFromDispatch {
    logout: typeof ActionCreators.logout;
}

type IAllProps = IProps & IPropsFromStore & IPropsFromDispatch;

const VdfAppBar = (props: IAllProps) => {

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);

    };
    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(null);
    };

    function logout(e) {
        props.logout();
        handleClose(e);
    }

    const open = Boolean(anchorEl);

    return (
        <AppBar position="static" className={classes.appBar}>
            <Toolbar>
                {
                    props.onBackClicked &&
                    (
                        <IconButton className={classes.backButton} aria-label="Zurück" onClick={props.onBackClicked}>
                            <ArrowBack/>
                        </IconButton>
                    )
                }
                {
                    props.displayLogo && (<img src={logo} alt=""/>)
                }
                <Typography className={classes.title} variant="h6" color="secondary">
                    {props.title}
                </Typography>

                {props.actions}

                <IconButton aria-label="Mehr"
                            aria-owns={open ? 'long-menu' : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}>
                    <MoreVert/>
                </IconButton>
                <Menu id="simple-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
                    <MenuItem onClick={logout} disabled={!props.isLogoutEnabled}>Logout</MenuItem>
                    <MenuItem onClick={handleClose}>
                        <Link className={classes.link} href="https://www.vdfnet.de/impressum" target="_blank"
                              rel="noopener">Impressum</Link>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <Link className={classes.link} href="https://www.vdfnet.de/datenschutz" target="_blank"
                              rel="noopener">Datenschutz</Link>
                    </MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
};

const useStyles = makeStyles((theme: Theme) => createStyles({
    appBar: {
        backgroundColor: 'white'
    },
    backButton: {
        padding: '8px'
    },
    title: {
        paddingLeft: '1rem',
        flexGrow: 1
    },
    link: {
        color: theme.palette.text.primary
    }
}));

const mapStateToProps = (state: RootState): IPropsFromStore => ({
    isLogoutEnabled: Selectors.isLoggedIn(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
    logout: ActionCreators.logout
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(VdfAppBar);




