import {ActionType, Category, PayloadArticleList} from "../model/model";
import {Selectors} from "../selectors";
import {api} from "../ReduxRoot";
import {RootState} from "../reducers";

export function loadArticles(page?: number) {
    return async (dispatch: Function, getState: Function) => {

        const state = getState();
        const pageToLoad = page ? page : Selectors.currentPage(state) + 1;
        const category = Selectors.selectActiveFilter(state);

        const LIMIT = 20;
        dispatch({type: ActionType.ARTICLE_LIST_START_LOADING});
        try {

            if (pageToLoad === 1) {
                const commentResponse = await api.fetchComments();
                dispatch({
                    type: ActionType.ARTICLE_LIST_COMMENTS,
                    payload: commentResponse.comments
                });
            }

            const articleListResponse = await api.fetchArticleList(LIMIT, (pageToLoad - 1) * LIMIT, category);
            dispatch({
                type: ActionType.ARTICLE_LIST_SUCCESS_LOADING,
                payload: {
                    articles: articleListResponse.articles,
                    category: category,
                    currentPage: articleListResponse.pages_current,
                    totalPages: articleListResponse.pages_total
                } as PayloadArticleList
            });
        } catch (e) {
            console.log(e);
            dispatch({type: ActionType.ARTICLE_LIST_ERROR_LOADING, payload: "Fehler beim Laden"});
        }

    };
}

export function setArticleFilter(category?: Category) {
    return async (dispatch: Function, getState: () => RootState) => {

        dispatch({type: ActionType.ARTICLE_LIST_FILTER, payload: category});
        dispatch(loadArticles());

    }
}




