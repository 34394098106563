import * as React from "react";
import {Dialog, DialogTitle, List, ListItem, ListItemIcon, ListItemText, Radio,} from "@material-ui/core";
import {Category} from "../../model/model";


interface Props {
    open: boolean;
    selectedCategory: Category;
    onClose: (category: Category) => void;
    categories: Map<Category, string>;
}

const CategoryDialog = (props: Props) => {


    const [selectedValue, setSelectedValue] = React.useState<Category>(props.selectedCategory);


    function handleClose() {
        props.onClose(selectedValue);
    }

    function onClick(cat: Category) {
        setSelectedValue(cat);
        props.onClose(cat);
    }

    function RadioListItem(props: { onClick: () => void, category: Category, label: string }) {
        return (
            <ListItem role={undefined} dense button onClick={props.onClick}>
                <ListItemIcon>
                    <Radio
                        checked={selectedValue === props.category}
                        onChange={props.onClick}
                        name="categories"
                    />
                </ListItemIcon>
                <ListItemText primary={props.label}/>
            </ListItem>
        );
    }


    return (
        <Dialog open={props.open} onClose={handleClose} aria-labelledby="simple-dialog-title">
            <DialogTitle id="simple-dialog-title">Neuigkeiten filtern</DialogTitle>
            <List>
                {Array.from(props.categories).map(([cat, name]) => (
                    <RadioListItem onClick={() => onClick(cat)} key={cat} category={cat} label={name}/>
                ))}
            </List>
        </Dialog>
    );
};


export default CategoryDialog;


