import createReducer from "./createReducer";
import {Action, ActionType,} from "../model/model";
import produce from "immer";


export interface CreateArticleState {
    isLoading: boolean,
    errorMessage?: string,
    showSuccessMessage: boolean
}

export const createArticleReducer = createReducer<CreateArticleState>({
    isLoading: false,
    showSuccessMessage: false
}, {
    [ActionType.CREATE_ARTICLE_START_LOADING](state: CreateArticleState, action: Action<void>) {
        return produce(state, draftState => {
            draftState.isLoading = true;
            draftState.errorMessage = undefined;
            draftState.showSuccessMessage = false;
        });
    },
    [ActionType.CREATE_ARTICLE_SUCCESS_LOADING](state: CreateArticleState, action: Action<void>) {
        return produce(state, draftState => {
            draftState.isLoading = false;
            draftState.showSuccessMessage = true;
        });
    },
    [ActionType.CREATE_ARTICLE_ERROR_LOADING](state: CreateArticleState, action: Action<string>) {
        return produce(state, draftState => {
            draftState.isLoading = false;
            draftState.errorMessage = action.payload;
        });
    },
    [ActionType.CREATE_ARTICLE_RESET](state: CreateArticleState, action: Action<void>) {
        return produce(state, draftState => {
            draftState.isLoading = false;
            draftState.errorMessage = undefined;
            draftState.showSuccessMessage = false;
        });
    },

});



