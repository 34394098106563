import {CssBaseline} from "@material-ui/core";
import {createMuiTheme} from "@material-ui/core/styles";
import {ThemeProvider} from "@material-ui/styles";
import * as React from "react";

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
    palette: {
        primary: {
            light: "#4e59ba",
            main: "#0a3089",
            dark: "#000c5b",
            contrastText: "#fff",
        },
        secondary: {
            light: "#ff6c56",
            main: "#e2352b",
            dark: "#a80001",
            contrastText: "#fff",
        },
    },
    typography: {
        fontFamily: [
            'CenturyGothicStd',
            'Arial',
            'Helvetica',
            'sans-serif'
        ].join(",")
    }
});

function withRoot(Component: any) {
    function WithRoot(props: object) {
        // MuiThemeProvider makes the theme available down the React tree
        // thanks to React context.
        return (
            <ThemeProvider theme={theme}>
                {/* Reboot kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline/>
                <Component {...props} />
            </ThemeProvider>
        );
    }

    return WithRoot;
}

export default withRoot;
