import {createStyles, makeStyles} from "@material-ui/styles";
import * as React from "react";
import {Snackbar, SnackbarContent, Theme} from "@material-ui/core";


interface Props {
    errorMessage?: string
}

const ErrorSnackbar = (props: Props) => {

    const [isOpen, setOpen] = React.useState(!!props.errorMessage);

    React.useEffect(() => {
        setOpen(!!props.errorMessage)
    }, [props.errorMessage]);

    const classes = useStyles();

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            open={isOpen}
            onClose={() => setOpen(false)}
            autoHideDuration={6000}
            ContentProps={{
                'aria-describedby': 'message-id',
            }}>
            <SnackbarContent className={classes.snackbar}
                             message={<span id="message-id">{props.errorMessage}</span>}/>
        </Snackbar>
    );
};

const useStyles = makeStyles((theme: Theme) => createStyles({
    snackbar: {
        backgroundColor: theme.palette.error.dark
    },
}));

export default ErrorSnackbar;


