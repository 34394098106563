export class ApiError extends Error {

    public code: string;

    constructor(code: string, description: string) {
        super(description);

        Object.setPrototypeOf(this, ApiError.prototype);
        this.code = code;
    }
}

export class NetworkError extends ApiError {

    constructor() {
        super("network", "Es konnte keine Verbindung zum Server hergestellt werden.");
    }
}

