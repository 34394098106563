import createReducer from "./createReducer";
import {Action, ActionType, PayloadLoginSuccess} from "../model/model";

export interface UserState {
    sessionId?: string,
    username?: string,
    password?: string,
}

export const userReducer = createReducer<UserState>({
}, {
    [ActionType.LOGIN_SUCCESS](state: UserState, action: Action<PayloadLoginSuccess>) {
        return {
            ...state,
            ...action.payload,
        };
    },
    [ActionType.LOGOUT_SUCCESS](state: UserState, action: Action<void>) {
        return {
            ...state,
            sessionId: undefined,
            username: undefined,
            password: undefined,
        };
    }
});

