import {RootState} from "../reducers";

export function createArticleIsLoading(state: RootState): boolean {
    return state.createArticleState.isLoading;
}

export function createArticleErrorMessage(state: RootState): string | undefined {
    return state.createArticleState.errorMessage;
}

export function createArticleShowSuccessMessage(state: RootState): boolean {
    return state.createArticleState.showSuccessMessage;
}

