import * as React from "react";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import App from "./App";
import configureStore from "./configureStore";
import Api from "./util/api";
import LoadingSpinner from "./components/common/LoadingSpinner";

const {persistor, store} = configureStore();

//persistor.purge();

function ReduxRoot() {
    return (
        <Provider store={store}>
            <PersistGate
                loading={<LoadingSpinner/>}
                persistor={persistor}
            >
                <App/>
            </PersistGate>
        </Provider>
    );
}

export const api = new Api(store.dispatch, store.getState);
export default ReduxRoot;
