import {createStyles, makeStyles} from "@material-ui/styles";
import * as React from "react";
import {Button, Card, CardActions, CardContent, Theme} from "@material-ui/core";
import VdfAppBar from "../components/AppBar/VdfAppBar";
import {replace} from "connected-react-router";
import {RootState} from "../reducers";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";
import {resetCreateArticleState, saveArticle} from "../actions/createArticle";
import {Selectors} from "../selectors";
import CreateArticleForm from "../components/CreateArticle/CreateArticleForm";
import ErrorSnackbar from "../components/common/ErrorSnackbar";


interface IProps {
    isLoading: boolean,
    errorMessage?: string,
    shouldDisplaySuccessMessage: boolean
}

interface IPropsFromDispatch {
    replace: typeof replace,
    saveArticle: typeof saveArticle,
    resetCreateArticleState: typeof resetCreateArticleState
}

type IAllProps = IProps & IPropsFromDispatch;


const CreateArticlePage = (props: IAllProps) => {

    const classes = useStyles();

    React.useEffect(() => {
        console.log("init");
        props.resetCreateArticleState();
    }, []);


    return (
        <div className={classes.root}>
            <VdfAppBar
                title={"Neuer Artikel"}
                displayLogo={true}
                onBackClicked={() => props.replace("/")}
            />
            <div className={classes.container}>
                {props.shouldDisplaySuccessMessage ?
                    <Card className={classes.successMessage}>
                        <CardContent>
                            Vielen Dank! Nach einer kurzen Prüfung durch die Geschäftsstelle wird Ihr Beitrag
                            freigeschaltet.
                        </CardContent>
                        <CardActions>
                            <Button size="small" onClick={() => props.replace("/")}>Zurück</Button>
                        </CardActions>
                    </Card>
                    :
                    <CreateArticleForm saveArticle={props.saveArticle} isLoading={props.isLoading}/>}
            </div>
            <ErrorSnackbar errorMessage={props.errorMessage}/>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    container: {
        padding: theme.spacing(1),
        flex: '1 1 0',
    },
    successMessage: {
        margin: theme.spacing(1)
    }
}));

const mapStateToProps = (state: RootState): IProps => {
    return ({
        isLoading: Selectors.createArticleIsLoading(state),
        errorMessage: Selectors.createArticleErrorMessage(state),
        shouldDisplaySuccessMessage: Selectors.createArticleShowSuccessMessage(state)
    });
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
    replace: replace,
    saveArticle: saveArticle,
    resetCreateArticleState: resetCreateArticleState

}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(CreateArticlePage);


