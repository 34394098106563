import {createStyles, makeStyles} from "@material-ui/styles";
import * as React from "react";
import {List, Theme, Typography} from "@material-ui/core";
import ArticleComment from "./ArticleComment";
import {Comment} from "../../model/model";
import LoadingSpinner from "../common/LoadingSpinner";


interface Props {
    isLoadingComments: boolean,
    comments?: Comment[]
    errorMessage?: string
}

const CommentSection = (props: Props) => {

    const classes = useStyles();

    return (
        <section className={classes.comments}>
            <Typography className={classes.commentsHeadline} color="primary" variant="h4">
                Kommentare
            </Typography>
            {props.isLoadingComments && <LoadingSpinner/>}

            {props.comments && props.comments.length === 0 && (
                <Typography variant="caption" className={classes.noComments}>Keine Kommentare gefunden</Typography>
            )}

            {props.errorMessage && (
                <Typography variant="caption" color="error"
                            className={classes.noComments}>{props.errorMessage}</Typography>
            )}

            {props.comments && (
                <List>
                    {props.comments.map(c => {
                        return (
                            <ArticleComment
                                key={c.id}
                                author={c.name}
                                comment={c.comment}
                                publishedAt={c.publish_up}
                                responseTo={c.answers}
                            />
                        )
                    })}
                </List>
            )}

        </section>
    );
};

const useStyles = makeStyles((theme: Theme) => createStyles({
    comments: {
        padding: theme.spacing(3, 0)
    },
    commentsHeadline: {
        fontSize: '1rem',
        padding: theme.spacing(0, 2, 1, 2)
    },
    noComments: {
        padding: theme.spacing(1, 2, 0, 2),
        fontStyle: 'italic',
    }

}));

export default CommentSection;


