import * as React from 'react';
import {Redirect, Route, RouteProps} from "react-router-dom";

interface IProps {
    component: any;
    isSignedIn: boolean;
}

type IAllProps = IProps & RouteProps;

export const PrivateRoute = ({component: Component, isSignedIn, ...rest}: IAllProps) => {

    return (
        <Route {...rest}
               render={
                   (routeProps) => isSignedIn ?
                       (
                           <Component {...routeProps} />
                       ) : (
                           <Redirect to={{pathname: '/login', state: {from: routeProps.location}}}/>
                       )
               }
        />
    );
};