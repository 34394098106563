import moment from "moment";
import 'moment/locale/de';

export function pick<T, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> {
    const ret: any = {};
    keys.forEach(key => {
        ret[key] = obj[key];
    });
    return ret;
}

export function formatRelativeDate(date: string): string {
    const mom = moment(date);
    mom.locale('de');
    return mom.fromNow();
}