import {createStyles, makeStyles} from "@material-ui/styles";
import * as React from "react";
import {Avatar, Theme} from "@material-ui/core";
import {Category} from "../../../model/model";
import arbeitsgruppen from "./arbeitsgruppen.svg";
import mitglieder from "./mitglieder.svg";
import vorstand from "./vorstand.svg";

interface Props {
    category: Category
}

const VdfAvatar = (props: Props) => {

    const classes = useStyles();


    return (
        <Avatar aria-hidden={true} className={classes.avatar}>
            {props.category === Category.AUS_ARBEITSGRUPPEN && <img src={arbeitsgruppen} alt=""/>}
            {props.category === Category.VOM_VORSTAND && <img src={vorstand} alt=""/>}
            {props.category === Category.VON_MITGLIEDERN && <img src={mitglieder} alt=""/>}
        </Avatar>
    );
};

const useStyles = makeStyles((theme: Theme) => createStyles({
    avatar: {
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: theme.palette.secondary.main,
        backgroundColor: 'white',
    },
}));

export default VdfAvatar;


