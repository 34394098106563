import {createStyles, makeStyles} from "@material-ui/styles";
import * as React from "react";
import {connect} from "react-redux";
import {Link, LinkProps, RouteComponentProps} from "react-router-dom";
import {RootState} from "../reducers";
import VdfAppBar from "../components/AppBar/VdfAppBar";
import {Badge, Fab, IconButton, Theme} from "@material-ui/core";
import {bindActionCreators, Dispatch} from "redux";
import {Article, Category} from "../model/model";
import {Selectors} from "../selectors";
import {ActionCreators} from "../actions";
import ArticleList from "../components/ArticleList/ArticleList";
import {FilterList} from "@material-ui/icons";
import CategoryDialog from "../components/CategoryDialog/CategoryDialog";
import ErrorSnackbar from "../components/common/ErrorSnackbar";
import AppInstallationBanner from "../components/common/AppInstallationBanner";
import AddIcon from '@material-ui/icons/Add';

interface IProps {
    articles: Article[],
    canLoadMoreArticles: boolean,
    activeFilter: Category,
    errorMessage?: string,
}

interface IPropsFromDispatch {
    loadArticles: typeof ActionCreators.loadArticles,
    setFilter: typeof ActionCreators.setArticleFilter,
}

type IAllProps = IProps & IPropsFromDispatch & RouteComponentProps<void>;


const ArticleListPage = (props: IAllProps) => {
    const classes = useStyles();

    const [isDialogOpen, setIsDialogOpen] = React.useState(false);

    React.useEffect(() => {
        props.loadArticles(1);
    }, []);

    React.useEffect(() => {

        const handler = () => {
            if (document.hidden === false) {
                props.loadArticles(1);
            }
        };

        document.addEventListener('visibilitychange', handler, false);

        return () => document.removeEventListener('visibilitychange', handler);
    }, []);


    function handleDialogClosed(selectedCategory: Category) {
        setIsDialogOpen(false);
        props.setFilter(selectedCategory);
    }

    let categories = new Map([
        [Category.NONE, "Gesamter Blog"],
        [Category.VON_MITGLIEDERN, "Von Mitgliedern für Mitglieder"],
        [Category.VOM_VORSTAND, "Von Vorstand & Geschäftsstelle"],
        [Category.AUS_ARBEITSGRUPPEN, "Aus den Arbeitsgruppen"],
    ]);

    const listWrapperId = "list-wrapper";

    const RouterLink = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => (
        <Link innerRef={ref as any} {...props} />
    ));

    return (
        <div className={classes.root}>
            <VdfAppBar
                title={"Mitglieder-Blog"}
                displayLogo={true}
                actions={(
                    <IconButton aria-label="Mehr" onClick={() => setIsDialogOpen(true)}>
                        <Badge color="secondary" variant="dot" invisible={props.activeFilter === Category.NONE}>
                            <FilterList/>
                        </Badge>
                    </IconButton>
                )}/>
            <AppInstallationBanner/>
            <div className={classes.content} id={listWrapperId}>
                <ArticleList
                    scrollableTarget={listWrapperId}
                    loadFunc={props.loadArticles}
                    hasMore={props.canLoadMoreArticles}
                    articles={props.articles}/>

            </div>
            <ErrorSnackbar errorMessage={props.errorMessage}/>

            <CategoryDialog open={isDialogOpen} selectedCategory={props.activeFilter} onClose={handleDialogClosed}
                            categories={categories}/>

            <Fab color="secondary" component={RouterLink} to="/neu" aria-label="Beitrag schreiben"
                 className={classes.fab}>
                <AddIcon/>
            </Fab>

        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        height: "100%",
        flexGrow: 1,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column'
    },
    content: {
        padding: '8px 8px',
        overflowY: 'auto'
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    }

}));

const mapStateToProps = (state: RootState): IProps => ({
    articles: Selectors.articleList(state),
    canLoadMoreArticles: Selectors.canLoadMoreArticles(state),
    activeFilter: Selectors.selectActiveFilter(state),
    errorMessage: Selectors.articleErrorMessage(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
    loadArticles: ActionCreators.loadArticles,
    setFilter: ActionCreators.setArticleFilter,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(ArticleListPage);
