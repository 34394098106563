import {TextField, Theme} from "@material-ui/core";
import * as React from "react";
import {createStyles, makeStyles} from "@material-ui/styles";
import LoadingButton from "../common/LoadingButton";
import 'tinymce/tinymce';
import 'tinymce/themes/silver';
import 'tinymce/themes/mobile';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/autolink';
import {Editor} from '@tinymce/tinymce-react';
import {Category} from "../../model/model";
import {saveArticle} from "../../actions/createArticle";


interface IProps {
    saveArticle: typeof saveArticle,
    isLoading: boolean
}

function CreateArticleForm(props: IProps) {

    const classes = useStyles();

    const [title, setTitle] = React.useState("");
    const [text, setText] = React.useState("");
    const [canSubmit, setCanSubmit] = React.useState(false);

    React.useEffect(() => {
        setCanSubmit(title.length > 3 && text.length > 10);
    }, [title, text]);

    const saveArticle = () => {
        props.saveArticle(title, text, Category.VON_MITGLIEDERN);
    };

    const handleEditorChange = (content) => {
        setText(content);
    };

    return <div className={classes.content}>
        <TextField
            variant={"outlined"}
            label={"Titel"}
            className={classes.title}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
        />
        <div className={classes.editorContainer}>
            <Editor
                value={text}
                onEditorChange={handleEditorChange}
                init={{
                    height: '100%',
                    plugins: "lists autolink",
                    toolbar: "undo redo | bold italic | link unlink | bullist numlist | removeformat",
                }}
                onSubmit={() => console.log("Hide")}
            />
        </div>
        <LoadingButton
            title={"Artikel veröffentlichen"}
            variant={"contained"}
            color={"primary"}
            disabled={!canSubmit}
            isLoading={props.isLoading}
            className={classes.button}
            onClick={saveArticle}
            buttonClass={classes.buttonInner}
        />
    </div>;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    editorContainer: {
        flex: '1 1 0',
    },
    title: {
        margin: theme.spacing(1, 0),
        width: '100%'
    },
    button: {
        margin: theme.spacing(1, 0),
        width: '100%'
    },
    buttonInner: {
        width: '100%'
    }
}));

export default CreateArticleForm;
