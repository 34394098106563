// prettier-ignore

import * as React from "react";
import {connect} from "react-redux";
import {Redirect, Route, RouteComponentProps, Switch} from "react-router-dom";
import {history} from "./configureStore";
import {RootState} from "./reducers/index";
import withRoot from "./withRoot";
import {PrivateRoute} from "./components/common/PrivateRoute";
import LoginPage from "./pages/LoginPage";
import {Selectors} from "./selectors";
import ArticleListPage from "./pages/ArticleListPage";
import {ConnectedRouter} from "connected-react-router";
import ArticleDetailPage from "./pages/ArticleDetailPage";
import ErrorSnackbar from "./components/common/ErrorSnackbar";
import CreateArticlePage from "./pages/CreateArticlePage";


interface IProps {
    isLoggedIn: boolean,
    logoutError?: string
}

type IAllProps = IProps & RouteComponentProps<void>;

function App(props?: IAllProps) {

    if (!props) {
        return null;
    }

    return (
        <React.Fragment>
            <ConnectedRouter history={history}>
                <Switch>
                    <Route exact={true} path="/login" component={LoginPage}/>
                    <PrivateRoute isSignedIn={props.isLoggedIn} exact={true} path="/" component={ArticleListPage}/>
                    <PrivateRoute isSignedIn={props.isLoggedIn} exact={true} path="/artikel/:articleId"
                                  component={ArticleDetailPage}/>
                    <PrivateRoute isSignedIn={props.isLoggedIn} exact={true} path="/neu" component={CreateArticlePage}/>
                    <Redirect to="/"/>
                </Switch>
            </ConnectedRouter>
            {props.logoutError && <ErrorSnackbar errorMessage={props.logoutError}/>}
        </React.Fragment>
    );
}


const mapStateToProps = (state: RootState): IProps => ({
    isLoggedIn: Selectors.isLoggedIn(state),
    logoutError: Selectors.logoutErrorMessage(state)
});

export default connect(mapStateToProps)(withRoot(App));
