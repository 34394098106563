import {createStyles, makeStyles} from "@material-ui/styles";
import * as React from "react";
import {CircularProgress, Theme} from "@material-ui/core";


const LoadingSpinner = () => {

    const classes = useStyles();

    return (
        <CircularProgress className={classes.progress} color="secondary"/>
    );
};

const useStyles = makeStyles((theme: Theme) => createStyles({
    progress: {
        position: 'relative',
        marginLeft: '50%',
        marginTop: 50,
        left: -20
    }
}));

export default LoadingSpinner;


